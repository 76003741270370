import React, { useState, useEffect, useRef } from 'react'
import {
  CardBody,
  Row,
  Button,
  Col,
  Card,
  CardHeader,
  Form,
  FormFeedback,
  Alert,
  FormGroup,
  Input,
  InputGroup,
} from 'reactstrap';
import { observer } from 'mobx-react'
import PlyrComponent from 'plyr-react';
import Crunker from 'crunker'

import AudioRecorder from 'components/recorder/AudioRecorder'
import uploadVoice from 'requests/uploadVoice'
import Voice from 'models/Voice'

function VoiceForm({user, onUploadComplete}) {
  const [identity, setIdentity] = useState('')
  const [gender, setGender] = useState(null)
  const [coughType, setCoughType] = useState('')
  const [disease, setDisease] = useState('')
  const [audioSrc, setAudioSrc] = useState(null)
  const [blob, setBlob] = useState(null)
  const [status, setStatus] = useState('')
  const [alertColor, setAlertColor] = useState('success')
  const [message, setMessage] = useState('')
  const [alertVisible, setAlertVisible] = useState(false);
  const textInput = useRef(null);

  useEffect(() => {
    if (disease === 'covid') {
      setCoughType('Covid_19')
    } else if (disease === "no-disease") {
      setCoughType('No_Disease')
    } else {
      setCoughType('')
    }
  }, [disease])
    
  useEffect(() => {
    let alertTimeout = null;
    if (status==='success') {
      setAlertColor('success')
      setAlertVisible(true)
      alertTimeout = setTimeout(() => {
        setAlertVisible(false)
        resetData()
        clearTimeout(alertTimeout)
      }, 1300)
    } else if(status==='error') {
      setAlertColor('danger')
      setAlertVisible(true)
      alertTimeout = setTimeout(() => {
        setAlertVisible(false)
        setStatus('');
        clearTimeout(alertTimeout)
      }, 1300)
    }
  }, [status])

  function onStopRecording(_base64data, thiBlob) {
    setBlob(thiBlob)
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(thiBlob);
    fileReader.onloadend = () => {
      const arrayBuffer = fileReader.result;
      const audioContext = new AudioContext({sampleRate: 44100});
      console.log(thiBlob)
      console.log(audioContext)
      audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
        const crunkerInstance = new Crunker({ sampleRate: 44100 });
        const finalAudio = crunkerInstance.export(audioBuffer, 'audio/wav');
        setAudioSrc(finalAudio.url)
      });
    };
  }

  const dismissAlert = () => setAlertVisible(false);
  
  const resetData = () => {
    setIdentity('');
    setAudioSrc(null);
    setBlob(null);
    setGender(null)
    setDisease('')
    setCoughType('')
    setStatus('');
    setMessage('');
  }

  async function handleUpload() {
    setStatus('uploading')
    const { token } = user
    let result = await uploadVoice({token, identity, gender, blob, coughType})
    if (result.status === 'success') {
      let { id, identity, file, gender, cough_type } = result.data
      setMessage('Success upload voice')
      setStatus('success')
      const voice = new Voice(id, identity, gender, file, null, cough_type)
      onUploadComplete(voice)
    } else if (result.status === 'error_response') {
      let { status, data } = result.error
      if (status === 400) {
        setMessage(data)
        setStatus('input_error')
      } else {
        setMessage('Failed to upload voice')
        setStatus('error')
      }
    } else if (result.status === 'error_request') {
      setMessage('Request error')
      setStatus('error')
    } else {
      setMessage('Unknown Error')
      setStatus('error')
    }
  }

  return (
    <Card className="mb-4">
      <CardHeader className="bg-white shadow border-0">
        <Row className="align-items-center">
          <Col xs="12" className="text-center">
            <h2 className="mb-0">Input Voice</h2>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="text-center px-3">
        <AudioRecorder
          className="my-2"
          onStop={onStopRecording}
          disabled={false}
        />
      <Col className="lead pb-2 justify-content-center align-items-center px-0">
        <Alert color={alertColor} isOpen={alertVisible} className="mx-auto" toggle={dismissAlert} style={{width: '500px', maxWidth: '95%'}}>
          <span className="alert-inner--text">
            <div>{message}</div>
          </span>
        </Alert>
        <Form role="form" style={{width: '500px', maxWidth: '95%'}} className="mx-auto">
          <FormGroup>
            <div style={{display: audioSrc===null ? 'none' : '' }}>
              <PlyrComponent
                source={{type: 'audio', sources: [{src: audioSrc, type:'audio/wav'}]}}
                options={{settings: [], disableContextMenu: true}}
              />
            </div>
          </FormGroup>
          <FormGroup className={ message.identity ? 'has-danger' : ''}>
            <InputGroup className="input-group-alternative">
              <Input
                id="identifier-input"
                placeholder="Input patient identifier"
                type="text"
                onInput={(e) => { setIdentity(e.target.value) }}
                innerRef={textInput}
                className={message.identity ? 'is-invalid' : ''}
                value={identity}
              />
              <FormFeedback tooltip>{message.identity ? message.identity[0] : '' }</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup className="mb-1">
            <InputGroup>
              <div className="mr-4 pt-2 d-inline">
                <label style={{fontSize:'12pt'}}>Gender :</label>
              </div>
              <Col sm="6" md="0" className="p-0 m-0"/>
              <div className="custom-control custom-control-alternative custom-radio mb-2 mr-3 pt-0 pt-md-2 d-inline">
                <input
                  className="custom-control-input"
                  id="male-gender-input"
                  name="gender"
                  type="radio"
                  value={0}
                  checked={gender === "0"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label className="custom-control-label" htmlFor="male-gender-input">
                  Male
                </label>
              </div>
              <div className="custom-control custom-control-alternative custom-radio mb-2 pt-0 pt-md-2 md-d-inline">
                <input
                  className="custom-control-input"
                  id="female-gender-input"
                  name="gender"
                  type="radio"
                  value={1}
                  checked={gender === "1"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label className="custom-control-label" htmlFor="female-gender-input">
                  Female
                </label>
              </div>
              <Input id="gender-input" style={{display:"none"}} className={message.gender ? 'is-invalid' : ''} />
              <FormFeedback tooltip>{message.gender ? message.gender[0] : '' }</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <div className="mr-4 pt-2 d-inline">
                <label style={{fontSize:'12pt'}}>Cough type :</label>
              </div>
              <Col sm="6" md="0" className="p-0 m-0"/>
              <div className="custom-control custom-control-alternative custom-radio mb-3 mr-3 pt-0 pt-md-2 d-inline">
                <input
                  className="custom-control-input"
                  id="no-disease-cough-input"
                  name="cough-type"
                  type="radio"
                  value={"no-disease"}
                  checked={disease === "no-disease"}
                  onChange={(e) => setDisease(e.target.value)}
                />
                <label className="custom-control-label" htmlFor="no-disease-cough-input">
                  No disease
                </label>
              </div>
              <div className="custom-control custom-control-alternative custom-radio mb-3 mr-3 pt-0 pt-md-2 d-inline">
                <input
                  className="custom-control-input"
                  id="covid-cough-input"
                  name="cough-type"
                  type="radio"
                  value={"covid"}
                  checked={disease === "covid"}
                  onChange={(e) => setDisease(e.target.value)}
                />
                <label className="custom-control-label" htmlFor="covid-cough-input">
                  Covid-19
                </label>
              </div>
              <div className="custom-control custom-control-alternative custom-radio mb-3 mr-3 pt-0 pt-md-2 d-inline">
                <input
                  className="custom-control-input"
                  id="other-cough-input"
                  name="cough-type"
                  type="radio"
                  value={"other"}
                  checked={disease === "other"}
                  onChange={(e) => setDisease(e.target.value)}
                />
                <label className="custom-control-label" htmlFor="other-cough-input">
                  Other
                </label>
              </div>
              <Input id="cough-input-0" style={{display:"none"}} className={message.cough_type ? 'is-invalid' : ''} />
              <FormFeedback style={{display: disease === "" ? "" : "none"}} tooltip>{message.cough_type ? message.cough_type[0] : '' }</FormFeedback>
              <InputGroup className="input-group-alternative ml-xs-0 other-cough-input">
                <Input
                  id="cough-input-1"
                  placeholder="Input cough type"
                  type="text"
                  onInput={(e) => { setCoughType(e.target.value) }}
                  className={message.cough_type ? 'is-invalid' : ''}
                  value={coughType}
                  style={{display: disease === "other" ? "" : "none"}}
                />
                <FormFeedback tooltip style={{display: disease === "other" ? "" : "none"}}>{message.cough_type ? message.cough_type[0] : '' }</FormFeedback>
              </InputGroup>
            </InputGroup>
          </FormGroup>
          <div className="text-center">
            <Button
              className="my-2"
              color="default"
              type="button"
              onClick={() => handleUpload()}
              disabled={audioSrc===null || status==="uploading"}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Col>
      </CardBody>
    </Card>
  )
}

export default observer(VoiceForm)