import {
  makeAutoObservable,
  autorun,
} from 'mobx';

class Voice {
  id = ''
  identity = ''
  gender = 0 // 0: male, 1: female
  coughType = ''
  source = ''
  blob = ''

  constructor(id, identity, gender, source, blob, coughType) {
    makeAutoObservable(this)
    this.id = id
    this.identity = identity
    this.gender = gender
    this.source = source
    this.blob = blob
    this.coughType = coughType
    autorun(() => console.log(this.log))
  }

  get log() {
    return {
      id: this.id,
      identity: this.identity,
      gender: this.gender,
      blob: this.blob,
      source: this.source,
      coughType: this.coughType
    }
  }

  get hasId(){
    return this.id && this.id !== ''
  }

  get isMale(){
    return this.gender === 0
  }

  get isFemale(){
    return this.gender === 1
  }

  setId(id) {
    this.id = id
  }

  setIdentity(identity) {
    this.identity = identity
  }

  setMale(){
    this.gender = 0
  }

  setFemale(){
    this.gender = 1
  }

  setBlob(blob) {
    this.blob = blob
  }

  setSource(source) {
    this.source = source
  }
}

export default Voice;