import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  CardFooter,
  Collapse,
} from 'reactstrap'
import { observer } from 'mobx-react'

import VoiceForm from 'components/VoiceForm'
import Voice from 'models/Voice'
import getSubmission from 'requests/getSubmission'
import VoiceList from 'components/VoiceList'

import { submission } from 'models/Submission'

function CollectVoice({ user }) {
  const [isGuidelineVisible, setGuidelineVisible] = useState(
    localStorage.getItem('guidelineVisible') ? Boolean(Number(localStorage.getItem('guidelineVisible'))) : true
  )
  const [next, setNext] = useState(null)
  
  useEffect(() => {
    async function getSubmissionData() {
      submission.clearData()
      const {token} = user
      let submissionData = await getSubmission({token, next:''})
      if (submissionData.status === 'success') {
        const voiceListData = submissionData.data.results.map(
          ({ file, gender, id, identity }) => new Voice(id, identity, gender, file, null, true)
        )
        setNext(submissionData.data.next)
        submission.mergeVoices(voiceListData)
      }
    }
    getSubmissionData()
  }, [user, user.token])

  function toggleGuidelineVisibility() {
    if (localStorage.getItem('guidelineVisible') === null){
      localStorage.setItem('guidelineVisible', 0)
    }
    setGuidelineVisible(!isGuidelineVisible)
  }

  const addVoice = (voice) => {
    submission.unshiftVoice(voice)
  }

  return (
    <>
      {/* Page content */}
      <Container className="mt-5" fluid style={{ minHeight: '65vh' }}>
        <Row className="justify-content-center">
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow mb-3">
              <CardHeader className="bg-white shadow border-0">
                <Row className="align-items-center">
                  <Col xs="12" className="text-center">
                    <h2 className="mb-0">Voice Submission Guideline</h2>
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={isGuidelineVisible}>
                <CardBody>
                  <h3>Petunjuk:</h3>
                  <ul className="mb-4 pl-4">
                    <li>
                      Untuk memulai dan menghentikan perekaman, tekan tombol perekaman yang telah disediakan
                    </li>
                    <li>
                      Pastikan data pasien telah dimasukkan
                    </li>
                    <li>
                      Sebelum mengunggah hasil rekaman anda, putar audio yang ditampilkan untuk memastikan kualitas suara
                    </li>
                  </ul>
                  <h3>Disclaimer:</h3>
                  <ul className="mb-0 pl-4">
                    <li>Data suara yang diambil dari sukarelawan pada penelitian ini akan tetap <b>anonim</b> sehingga sukarelawan tidak perlu khawatir dengan privasi data mereka</li>
                  </ul>
                </CardBody>
              </Collapse>
              <CardFooter className="bg-white shadow border-0 text-center">
                <Button
                  color="default"
                  onClick={toggleGuidelineVisibility}
                >
                  {
                    isGuidelineVisible ? "Close Guideline" : "Open Guideline"
                  }
                </Button>
              </CardFooter>
            </Card>
            <VoiceForm user={user} onUploadComplete={addVoice} />
            {
              submission.voiceList.length > 0
              && <VoiceList user={user} submission={submission} nextUrl={next}/>
            }
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default observer(CollectVoice);
