import React, { useState } from 'react'
import {
  Button
} from 'reactstrap'

import createMediaRecorder from './record'

function AudioRecorder({
  onMediaRecorderCreated,
  onStart,
  onStop,
  disabled,
  ...props
}) {
  const [mediaRecorder, setMediaRecorder] = useState('')
  const [isRecording, setRecording] = useState(false)

  const constraints = {
    video: false,
    audio: {
      channelCount: 2,
      sampleRate: 44.1,
    },
  };


  function startRecording() {
    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        let recorder = createMediaRecorder(stream, onStop)
        setMediaRecorder(recorder)
        recorder.start()
        if (onMediaRecorderCreated) {
          onMediaRecorderCreated(recorder)
        }
        setRecording(true)
        if (onStart) {
          onStart()
        }
      })
      .catch(function (err) {
        console.log(err);
        alert('getUserMedia() error: ' + err.name);
      });
  }

  function stopRecording() {
    setRecording(false)
    mediaRecorder.stop()
  }

  function handleClick() {
    if (isRecording) {
      stopRecording()
    } else {
      startRecording()
    }
  }

  return (
    <div {...props}>
      <Button
        className={`btn-icon btn-2 rounded-circle recorder-btn ${isRecording ? 'recording': ''}`}
        onClick={() => handleClick()}
        disabled={disabled}
        color={ isRecording? "danger": "default"}
        type="button"
        size="lg"
        style={{ width: '5rem', height: '5rem', fontSize:'2rem' }}
      >
        {
          isRecording ? <i className="fas fa-microphone-slash" /> : <i className="fas fa-microphone" />
        }
      </Button>
    </div>
  )
}

export default AudioRecorder