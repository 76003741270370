import React, { useState } from 'react';
import {
  Modal,
  // Button,
  Card,
  CardBody,
  CardHeader,
  // Form,
  // FormGroup,
  // Input,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  // FormFeedback,
  NavbarBrand,
  // CardFooter
} from 'reactstrap';

// import LogoProsa from 'assets/logo-centered.svg';
import { observer } from 'mobx-react';
import Welcome from 'components/identifiers/Welcome';
import Login from 'components/identifiers/Login';
import CreateAccount from 'components/identifiers/CreateAccount';

const IdentifierModal = observer(({user}) => {
  const [stage, setStage] = useState('welcome');
  function setRegister(){
    setStage('register')
  }

  function setLogin(){
    setStage('login')
  }

  function setWelcome(){
    setStage('welcome')
  }

  // const textInput = useRef(null);
  
  // const logo={
  //   innerLink: '/',
  //   imgSrc: LogoProsa,
  //   imgAlt: 'Prosa.ai'
  // }

  const stageMap = {
    'welcome': (
      <Welcome
        register={setRegister}
        login={setLogin} />
    ),
    'register': (
      <CreateAccount
        user={user}
        back={setWelcome}
        onSuccess={setLogin}
      />
    ),
    'login': (
      <Login
        user={user}
        back={setWelcome}
      />
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={!user.isAuth}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-center mt-1 mb-1">
              <NavbarBrand className="pt-0">
                {/* <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img w-70"
                  src={logo.imgSrc}
                /> */}
                <h1 className="mb-0">
                  {stage.charAt(0).toUpperCase() + stage.slice(1)}
                </h1>
              </NavbarBrand>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-4">
            {
              stageMap[stage]
            }
          </CardBody>
          {/* <CardFooter className="bg-transparent text-center">
            Pengumpulan Data ini bagian dari Penelitian Biometrik Ucapan Pusat AI ITB
          </CardFooter> */}
        </Card>
      </div>
    </Modal>
  );
})

export default IdentifierModal;