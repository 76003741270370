/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container } from "reactstrap";
import { observer } from 'mobx-react'

// core components
import IdentifierModal from 'views/IdentifierModal';
import CollectVoice from 'views/CollectVoice';

import Header from "components/Header";
import Footer from 'components/Footer';
// import LogoProsa from 'assets/logo-centered.svg';

@observer
class DashboardLayout extends React.Component {
  
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  render() {
    let { user } = this.props;
    return (
      <>
        <IdentifierModal user={user}/>
        <div className="main-content" ref="mainContent">
          <Header user={user}/>
          {
            user.isAuth ? <CollectVoice user={user} /> :
            <div className="w-100" style={{ minHeight: '87vh' }} />
          }
          <Container fluid className="md-fixed-bottom">
            {/* <Footer /> */}
          </Container>
        </div>
      </>
    );
  }
}

export default DashboardLayout;
