import { makeAutoObservable, autorun } from 'mobx'

export function arrayUnique(array) {
  var a = array.concat();
  for(var i=0; i<a.length; ++i) {
      for(var j=i+1; j<a.length; ++j) {
          if(a[i].id === a[j].id)
              a.splice(j--, 1);
      }
  }

  return a;
}


class Submission {
  voiceList = [] 

  constructor(voiceList) {
    makeAutoObservable(this)
    this.voiceList = voiceList
    autorun(() => console.log(this.log))
  }

  get log() {
    return {
      voiceList: this.voiceList.map(element=> element && element.log )
    }
  }

  get length() {
    return this.voiceList.length
  }

  appendVoice(voice) {
    this.voiceList.push(voice)
  }

  unshiftVoice(voice) {
    this.voiceList.unshift(voice)
  }

  removeVoice(voice) {
    this.voiceList.splice(this.voiceList.indexOf(voice), 1)
  }

  mergeVoices(voiceList) {
    const filteredVoice = voiceList.filter(({identity}) => identity && identity !== "DELETED")
    this.voiceList = arrayUnique(this.voiceList.concat(filteredVoice)); 
  }
  
  clearData() {
    this.voiceList = []
  }
  
}

export default Submission

export const submission = new Submission([])