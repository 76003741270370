/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, Nav } from "reactstrap";

function Footer() {
    return (
      <footer className="footer bg-none">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="12">
            <Nav className="nav-footer justify-content-center">
              Copyright © 2020{" "}
              <a
                className="font-weight-bold ml-1"
                href="https://prosa.ai"
                rel="noopener noreferrer"
                target="_blank"
              >
                PT. Prosa Solusi Cerdas.
              </a>
              &nbsp; All rights reserved.
            </Nav>
          </Col>
        </Row>
      </footer>
    );
    }
export default Footer;
