import React from 'react'
import {
  Button,
} from 'reactstrap';

function Welcome({register, login}) {
  return (
    <>
      {/* <div className="text-center mb-4">
        Are you a new user?
      </div> */}
      <div className="text-center">
        <Button
          className="my-2 mx-auto btn-block"
          color="default"
          type="button"
          // eslint-disable-next-line react-hooks/rules-of-hooks
          onClick={() => login()}
        >
          Login
        </Button>
      </div>
      <div className="text-center mb-2">
        <Button
          className="my-2 mx-auto btn-block"
          color="default"
          type="button"
          onClick={() => register()}
        >
          Register
        </Button>
      </div>
    </>
  )
}

export default Welcome;
