/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { observer } from 'mobx-react'

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

function Header({ user }) {
  return (
    <>
      <div
        className="header py-4 d-flex align-items-center"
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center justify-content-center" fluid>
          <Row>
            <Col>
              {
                user.isAuth &&
                <>
                  <div className="text-center">
                    <h3 className="text-white mb-0 mx-auto">Account:</h3>
                  </div>
                  <div className="text-center">
                    <h2 className="display-5 text-white mx-auto">{user.email}</h2>
                  </div>
                  <div className="text-center">
                    <Button
                      className="mx-auto m-0 header-link"
                      size="sm"
                      onClick={() => user.logout()}
                    >
                      Logout
                    </Button>
                  </div>
                </>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default observer(Header);
