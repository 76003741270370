import axios from 'axios'
import getCookie from 'requests/getCookie'

let baseurl = '/auth/users/'

const csrftoken = getCookie('csrftoken');

export default async function createUser({
  email,
  password,
  firstName,
  lastName,
  organization
}) {
  let url = baseurl
  let result = {}
  let user = {
    username: email,
    email : email,
    password: password,
    first_name: firstName,
    last_name: lastName,
    organization: organization
  }
  const options = {
    method: 'POST',
    headers: { 
      'content-type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    data: JSON.stringify(user),
    url,
  }
  await axios(options).then(response => {
    result = {
      status: 'success',
      data: response.data
    }
  }).catch(error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      result = {
        status: 'error_response',
        error: {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      result = {
        status: 'error_request',
        error: error.request
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      result = {
        status: 'error',
        error: error.message
      }
    }
    console.log(error.config);
  });
  console.log(result)
  return result
}