let mediaRecorder = ''

export default function createMediaRecorder(stream, onStop) {
    let audioStream = new MediaStream();
    audioStream.addTrack(stream.getTracks()[0]);

    let recorderOptions = {
      mimeType: 'audio/webm',
    }

    mediaRecorder = new MediaRecorder(stream, recorderOptions);
    let chunks = [];

    mediaRecorder.ondataavailable = function (e) {
        chunks.push(e.data);
    }

    mediaRecorder.onstop = function () {
        stream.getTracks().forEach(function (track) {
            track.stop();
        });
        var blob = new Blob(chunks, { 'type': 'audio/wav; codecs=webm' });
        chunks = [];

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            let base64data = reader.result;
            if (onStop){
              onStop(base64data, blob)
            } else {
              console.log(base64data, blob)
            }
        }
    }
    return mediaRecorder
}

