import { makeAutoObservable, autorun } from 'mobx';

class User {
  email = ''
  isAuth = false
  token = ''

  constructor(email) {
    makeAutoObservable(this)

    this.email = email
    autorun(() => console.log(this.log))
  }

  get log() {
    return {
      email: this.email,
      isAuth: this.isAuth,
      token: this.token
    }
  }

  get hasEmail(){
    return this.email && this.email !== ''
  }

  setEmail(email) {
    this.email = email
  }

  setAuth(token){
    this.isAuth = true
    this.token = token
    localStorage.setItem('email', this.email)
    sessionStorage.setItem('token', this.token)
  }

  logout() {
    this.isAuth = false
    this.token = ''
    sessionStorage.removeItem('token')
  }
}

export default User;

const email = localStorage.getItem('email')
const token = sessionStorage.getItem('token')

let thisUser

if(email) {
  thisUser = new User(email)
  if (token ) {
    thisUser.setAuth(token)
  }
  
} else {
  thisUser = new User('')
}  


export const user = thisUser
