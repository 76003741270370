import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
} from 'reactstrap';
import { observer } from 'mobx-react'

import login from 'requests/Login';

function Login({ user, back }) {
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  const [alertVisible, setAlertVisible] = useState(false);
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState('')

  const textInput = useRef(null);

  const dismissAlert = () => setAlertVisible(false);
  
  useEffect(() => {
    if (textInput.current) {
      textInput.current.focus()
    }
  }, [])

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      loginRequest()
    }
  }

  async function loginRequest() {
    if (email === '') {
      setStatus('input_error')
      setMessage('Field can\'t be empty')
    } else {
      user.setEmail(email)
      setStatus('loading')
      let result = await login({email, password})
      if (result.status === 'success') {
        const { auth_token } = result.data
        user.setAuth(auth_token)
        back()
      } else if (result.status === 'error_response') {
        let { status, data } = result.error
        if (status === 400) {
          setStatus('input_error')
          if (data.non_field_errors) {
            setMessage(data)
            setAlertVisible(true)
          } else {
            setStatus('error')
            setMessage('Failed to validate email')
            setAlertVisible(true)
          }
        } else {
          setStatus('error')
          setMessage('Failed to validate email')
          setAlertVisible(true)
        }
      } else if (result.status === 'error_request') {
        setStatus('error')
        setMessage('Request error')
        setAlertVisible(true)
      } else {
        setStatus('error')
        setMessage('Unknown Error')
        setAlertVisible(true)
      }
    }
  }

  return (
    <>
      <Alert color="danger" isOpen={alertVisible} toggle={dismissAlert}>
        <span className="alert-inner--text">
          {
            message.non_field_errors ? <div>{message.non_field_errors[0]}</div> :
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
          }
        </span>
      </Alert>
      <Form role="form">
        <FormGroup className={status === 'input_error' ? 'has-danger' : ''}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i
                  className="fas fa-envelope"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="email-input"
              placeholder="Input your email"
              type="email"
              onInput={(e) => { setEmail(e.target.value) }}
              onKeyDown={(e) => { handleKeyDown(e) }}
              innerRef={textInput}
              value={email}
              className={status === 'input_error' ? 'is-invalid' : ''}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className={status === 'input_error' ? 'has-danger' : ''}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i
                  className="fas fa-lock"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="password-input"
              placeholder="Enter password"
              type="password"
              onInput={(e) => { setPassword(e.target.value) }}
              onKeyDown={(e) => { handleKeyDown(e) }}
              className={status === 'input_error' ? 'is-invalid' : ''}
            />
          </InputGroup>
        </FormGroup>
        <div className="text-center mb-3">
          <Button
            className="my-2"
            color="secondary"
            type="button"
            onClick={() => back()}
            disabled={status === 'loading'}
          >
            Back
          </Button>
          <Button
            className="my-2"
            color="default"
            type="button"
            onClick={() => loginRequest()}
            disabled={status === 'loading'}
          >
            Login
          </Button>
        </div>
      </Form>
    </>
  );
}

export default observer(Login);