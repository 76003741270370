import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Alert,
  Row, 
  Col
} from 'reactstrap';
import { observer } from 'mobx-react'

import createUser from 'requests/createUser'

const CreateAccount = observer(({ user, onSuccess, back }) => {
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')
  const [alertVisible, setAlertVisible] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [organization, setOrganization] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)

  const firstNameRef = useRef(null);

  const dismissAlert = () => setAlertVisible(false);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      registerUser()
    }
  }

  function togglePasswordVisible() {
    setPasswordVisible(!passwordVisible)
  }
  
  function handleTogglePassButton (e) {
    e.preventDefault()
    togglePasswordVisible()
  }

  useEffect(() => {
    firstNameRef.current.focus()
  }, [])

  async function registerUser() {
    user.setEmail(email)
    setStatus('loading')
    console.log(user)
    let result = await createUser({
      email,
      password,
      firstName,
      lastName,
      organization
    })

    if (result.status === 'success') {
      onSuccess()
    } else if (result.status === 'error_response') {
      let { status, data } = result.error
      if (status === 400) {
        setStatus('input_error')
        setMessage(data)
      } else {
        setStatus('error')
        setMessage('Failed to register identifier')
        setAlertVisible(true)
      }
    } else if (result.status === 'error_request') {
      setStatus('error')
      setMessage('Request error')
      setAlertVisible(true)
    } else {
      setStatus('error')
      setMessage('Unknown Error')
      setAlertVisible(true)
    }
  }

  return (
    <>
      <Alert color="danger" isOpen={alertVisible} toggle={dismissAlert}>
        <span className="alert-inner--text">
          <div dangerouslySetInnerHTML={{ __html: message }}></div>
        </span>
      </Alert>
      <Form role="form">
        <Row>
          <Col md="6">
            <FormGroup className={ message.first_name ? 'has-danger' : ''}>
              <InputGroup className="input-group-alternative">
                <Input
                  id="first-name-input"
                  placeholder="First Name"
                  type="text"
                  onInput={(e) => { setFirstName(e.target.value) }}
                  onKeyDown={(e) => { handleKeyDown(e) }}
                  innerRef={firstNameRef}
                  className={message.first_name ? 'is-invalid' : ''}
                />
                <FormFeedback tooltip>{message.first_name ? message.first_name[0] : '' }</FormFeedback>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className={ message.last_name ? 'has-danger' : ''}>
              <InputGroup className="input-group-alternative">
                <Input
                  id="last-name-input"
                  placeholder="Last Name"
                  type="text"
                  onInput={(e) => { setLastName(e.target.value) }}
                  onKeyDown={(e) => { handleKeyDown(e) }}
                  className={message.last_name ? 'is-invalid' : ''}
                />
                <FormFeedback tooltip>{message.last_name ? message.last_name[0] : '' }</FormFeedback>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className={ message.organization ? 'has-danger' : ''}>
          <InputGroup className="input-group-alternative">
            <Input
              id="org-name-input"
              placeholder="Organization"
              type="text"
              onInput={(e) => { setOrganization(e.target.value) }}
              onKeyDown={(e) => { handleKeyDown(e) }}
              className={message.organization ? 'is-invalid' : ''}
            />
            <FormFeedback tooltip>{message.organization ? message.organization[0] : '' }</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup className={ message.email || message.username ? 'has-danger' : ''}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i
                  className="fas fa-envelope"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="email-input"
              placeholder="Enter your email"
              type="email"
              onInput={(e) => { setEmail(e.target.value) }}
              onKeyDown={(e) => { handleKeyDown(e) }}
              className={message.email || message.username ? 'is-invalid' : ''}
            />
            <FormFeedback tooltip>{message.email ? message.email[0] : '' }</FormFeedback>
            <FormFeedback tooltip>{message.username ? message.username[0].replace('username','email') : '' }</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup className={ message.password ? 'has-danger' : ''}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i
                  className="fas fa-lock"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="password-input"
              placeholder="Enter password"
              type={ passwordVisible ? "text" : "password"}
              onInput={(e) => { setPassword(e.target.value) }}
              onKeyDown={(e) => { handleKeyDown(e) }}
              className={message.password ? 'is-invalid' : ''}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <Button
                  color="secondary"
                  onClick={handleTogglePassButton}
                  size="sm"
                  active
                  title={ passwordVisible ? "Hide Password" : "Show Password"}
                >
                  <i
                    className={ passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"}
                  />
                </Button>
              </InputGroupText>
            </InputGroupAddon>
            <FormFeedback tooltip>{message.password ? message.password[0] : '' }</FormFeedback>
          </InputGroup>
        </FormGroup>
        <div className="text-center">
          <Button
            className="my-2"
            color="secondary"
            type="button"
            onClick={() => back()}
            disabled={status === 'loading'}
          >
            Back
          </Button>
          <Button
            className="my-2"
            color="default"
            type="button"
            onClick={() => registerUser()}
            disabled={status === 'loading'}
          >
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
})

export default CreateAccount;