import React, {useState} from 'react';
import {
  Row, Col, Card, CardHeader, Button, Alert
} from 'reactstrap'
import PlyrComponent from 'plyr-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@bit/mui-org.material-ui.circular-progress';

import getSubmission from 'requests/getSubmission'
import deleteVoice from 'requests/deleteVoice'
import Voice from 'models/Voice'

function VoiceList({submission, user, nextUrl}) {
  const [next, setNext] = useState(nextUrl)
  const [isLoading, setIsLoading] = useState(false)
  const [alertColor, setAlertColor] = useState('')
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  async function handleDelete(voice) {
    let alertTimeout = null;
    setIsLoading(true)
    const {token} = user
    const { id } = voice
    let deleteResult = await deleteVoice({token, id})
    if (deleteResult.status === 'success') {
      setAlertMessage('Voice Deleted')
      setAlertColor('success')
      setAlertVisible(true)
      submission.removeVoice(voice)
    } else {
      setAlertMessage('Delete Failed')
      setAlertColor('danger')
      setAlertVisible(true)
    }
    alertTimeout = setTimeout(() => {
      setAlertVisible(false)
      setIsLoading(false)
      clearTimeout(alertTimeout)
    }, 1300)
  }
  
  async function fetchData() {
    const {token} = user
    let data = submission.voiceList
    let submissionData = await getSubmission({token, next})
    if (submissionData.status === 'success') {
      const voiceListData = submissionData.data.results.map(
        ({ file, gender, id, identity }) => new Voice(id, identity, gender, file, null, true)
      )
      setNext(submissionData.data.next)
      submission.mergeVoices(voiceListData)
    }
    return data
  }
  
  return(
    <>
      <Row className="justify-content-center">
        <Col>
          <Alert color={alertColor} isOpen={alertVisible}>
            <span className="alert-inner--text">
              {alertMessage}
            </span>
          </Alert>
        </Col>
      </Row>
      <InfiniteScroll
        dataLength={submission.voiceList.length} //This is important field to render the next data
        next={fetchData}
        hasMore={next!==null}
        loader={<div className="my-4 text-center"><CircularProgress /></div>}
        endMessage={''}
      >
        {
          submission.voiceList.map((voice, index) =>
            <Card className="bg-secondary shadow mb-3" key={'collection-' + index}>
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center justify-content-around py-0">
                  <Col xs="5" md="6" lg="8">
                    <h3 className="mb-0">{voice.identity}</h3>
                  </Col>
                  <Col xs="7" md="5" lg="3">
                    <Row className="align-items-center justify-content-end py-0">
                    <Col xs="7" sm="8" className="text-right px-0 px-md-2">
                      <PlyrComponent
                        source={{type: 'audio', sources: [{src: voice.source, type:'audio/wav', preload:"metadata"}]}}
                        options={{controls:['play','current-time']}}
                      />
                    </Col>
                    <Col xs="5" sm="4" className="text-right d-inline">
                      <Button
                        className="btn-icon btn-2"
                        color="secondary"
                        type="button"
                        onClick={() => handleDelete(voice)}
                        disabled={isLoading}
                        style={{ fontSize: '1rem' }}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          )
        }
      </InfiniteScroll>
    </>
  )
}

export default VoiceList;
