import React from 'react';

import 'App.css';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import 'plyr/src/sass/plyr.scss'

import Dashboard from 'layouts/Dashboard'

import { user } from './models/User'

export default function App() {
    return <Dashboard user={user}></Dashboard>
}
